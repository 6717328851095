











import { Vue, Component, Prop } from "vue-property-decorator";
import Contact, { getContactCountryName } from "@/entity/Contact";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";

@Component
export default class Address extends Vue {
  @State("countries", { namespace: "selectOptions" })
  countries!: ReferenceItem[];

  @Prop({ default: null }) contact!: Contact | null;

  get isEmpty(): boolean {
    if (!this.contact) {
      return true;
    }

    return (
      !this.contact?.address?.address &&
      !this.contact?.address?.postcode &&
      !this.contact?.address?.city &&
      !this.contact?.address?.country
    );
  }

  get countryName(): string {
    if (!this.contact) {
      return "";
    }

    return getContactCountryName(this.contact, this.countries);
  }

  get address(): string {
    if (!this.contact?.address?.address) return "";
    return this.contact.address.address;
  }

  get showAddress(): boolean {
    return this.contact?.address !== undefined;
  }

  get city(): string {
    return `${this.contact?.address?.postcode ?? ""} ${
      this.contact?.address?.city ?? ""
    }`;
  }
}
